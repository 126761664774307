exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-tools-buttercms-tsx": () => import("./../../../src/pages/tools/buttercms.tsx" /* webpackChunkName: "component---src-pages-tools-buttercms-tsx" */),
  "component---src-pages-tools-cksource-tsx": () => import("./../../../src/pages/tools/cksource.tsx" /* webpackChunkName: "component---src-pages-tools-cksource-tsx" */),
  "component---src-pages-tools-tiny-technologies-tsx": () => import("./../../../src/pages/tools/tiny-technologies.tsx" /* webpackChunkName: "component---src-pages-tools-tiny-technologies-tsx" */),
  "component---src-pages-tools-tsx": () => import("./../../../src/pages/tools.tsx" /* webpackChunkName: "component---src-pages-tools-tsx" */),
  "component---src-pages-tools-uploadcare-tsx": () => import("./../../../src/pages/tools/uploadcare.tsx" /* webpackChunkName: "component---src-pages-tools-uploadcare-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag-template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */)
}

